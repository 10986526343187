import AOS from "aos"
import "aos/dist/aos.css"
import { useEffect } from "react"


function ProjectModelDesktop({project, lang, number}) {
    console.log('number :>> ', number);

    useEffect(() => {
        AOS.init({duration: 2000});
    }, [])

    return (
        <div className="project-model-desktop" data-aos={number % 2 === 0 ? "fade-left" : "fade-right"}>
            <img className="project-model-img" src={project.img} alt={project.name} />
            <div className="project-model-text">
                <p className="project-model-headline project-model-headline-desktop">{project.name}</p>
                <p className="project-model-lang project-model-lang-desktop">{project.lang.map((lang, index) => {
                return <span key={index}>{lang}{index < project.lang.length - 1 ? ' |  ' : ''}</span>
                })
                }</p>
                <p className="project-model-desc project-model-desc-desktop">{lang === "de" ? project.descDE : lang === "en" ? project.descEN : ""}</p>
                <div className="project-model-buttons">
                <a href={project['test-url']} target="_blank" rel="noreferrer"><button className="project-model-button-live project-model-button-live-desktop">Live Test</button></a>
                <a href={project['git-url']} target="_blank" rel="noreferrer"><button className="project-model-button-github project-model-button-github-desktop">Github</button></a>
                </div> 
            </div>
        </div>
    )
}

export default ProjectModelDesktop
