import "./Contacts.css"
import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'
function ContactsDesktop({handleNameFocus, handleMessageFocus, handleMailFocus, nameCorrect, mailCorrect, messageCorrect, setPolicyCheck, policyCheck, setIsPrivate, lang}) {

    return (
        <div id="contacts" className="contacts contacts-desktop">
            <h1 className='headlines-desktop'>{lang === "de" ? langDe.contacts.title : lang === "en" ? langEn.contacts.title : ""}</h1>
            <div className="contact-content">
                <div>
                    <span>{lang === "de" ? langDe.contacts.text1 : lang === "en" ? langEn.contacts.text1 : ""}</span><br />
                    <span>{lang === "de" ? langDe.contacts.text2 : lang === "en" ? langEn.contacts.text2 : ""}</span><br />
                    <span>{lang === "de" ? langDe.contacts.text3 : lang === "en" ? langEn.contacts.text3 : ""}</span>
                </div>
                <div className="contact-form-con-desktop"> 
                    <form action="https://kay-schumacher.de/send_mail.php" method="post" className="contact-form">
                        <input name="name" type="text" placeholder={lang === "de" ? langDe.contacts.placeholder : lang === "en" ? langEn.contacts.placeholder : ""} required
                            className={nameCorrect === true ? "contact-input-default contact-input-green" : 
                                nameCorrect === "Start" ? "contact-input-default contact-input-empty" :
                                    "contact-input-default contact-input-error"}
                            maxLength="30"
                            onBlur={(e) => handleNameFocus(e.target.value)} 
                        />
                        <div className="contact-form-checkbox-error-message-con">
                            {nameCorrect === true || nameCorrect === "Start" ? null : <span className="color-red">{lang === "de" ? langDe.contacts.errorName : lang === "en" ? langEn.contacts.errorName : ""}</span>}
                        </div>
                        <input name="mail" type="mail" placeholder={lang === "de" ? langDe.contacts.placeholder2 : lang === "en" ? langEn.contacts.placeholder2 : ""} required 
                            className={mailCorrect === true ? "contact-input-default contact-input-green" :
                                mailCorrect === "Start" ? "contact-input-default contact-input-empty" :
                                "contact-input-default contact-input-error"} 
                            onBlur={(e) => handleMailFocus(e.target.value)} 
                        />
                        <div className="contact-form-checkbox-error-message-con">
                        {mailCorrect === true || mailCorrect === "Start" ? null :
                         mailCorrect === "at" ? <span className="color-red">{lang === "de" ? langDe.contacts.errorMail1 : lang === "en" ? langEn.contacts.errorMail1 : ""}</span> :
                         mailCorrect === "before" ? <span className="color-red">{lang === "de" ? langDe.contacts.errorMail2 : lang === "en" ? langEn.contacts.errorMail2 : ""}</span> :
                         mailCorrect === "points" ? <span className="color-red">{lang === "de" ? langDe.contacts.errorMail3 : lang === "en" ? langEn.contacts.errorMail3 : ""}</span> :
                         mailCorrect === "after" ? <span className="color-red">{lang === "de" ? langDe.contacts.errorMail4 : lang === "en" ? langEn.contacts.errorMail4 : ""}</span> :
                         mailCorrect === "points-after" ? <span className="color-red">{lang === "de" ? langDe.contacts.errorMail5 : lang === "en" ? langEn.contacts.errorMail5 : ""}</span> :
                         <span className="color-red">{lang === "de" ? langDe.contacts.errorMail6 : lang === "en" ? langEn.contacts.errorMail6 : ""}</span>}
                        </div>
                        <textarea id="message" name="message" required placeholder={lang === "de" ? langDe.contacts.placeholder3 : lang === "en" ? langEn.contacts.placeholder3 : ""} 
                            className={messageCorrect === "Start" ? "contact-input-default contact-textarea contact-input-empty" :
                                messageCorrect === true ? "contact-input-default contact-textarea contact-input-green" :
                                "contact-input-default contact-textarea contact-input-error"}
                            maxLength="450"
                            onBlur={(e) => handleMessageFocus(e.target.value)} />
                        <div className="contact-form-checkbox-error-message-con">
                            {messageCorrect === true || messageCorrect === "Start" ? null : <span className="color-red">{lang === "de" ? langDe.contacts.errorMessage : lang === "en" ? langEn.contacts.errorMessage : ""}</span>}
                        </div>
                        <div className="contact-form-checkbox-con">
                            <input type="checkbox" className="contact-checkbox" onClick={() => setPolicyCheck(!policyCheck)}/>
                            <span>{lang === "de" ? langDe.contacts.acceptthe : lang === "en" ? langEn.contacts.acceptthe : ""} <span className="contacts-policy" onClick={() => setIsPrivate(true)}>{lang === "de" ? langDe.contacts.privatepolicy : lang === "en" ? langEn.contacts.privactepolicy : ""}</span></span>
                        </div>
                        <div className="color-red contact-form-checkbox-error-con">
                            {policyCheck ? null : <span>{lang === "de" ? langDe.contacts.policyerror : lang === "en" ? langEn.contacts.policyerror : ""}</span>}
                        </div>
                        <button 
                        disabled={!(nameCorrect === true && mailCorrect === true && messageCorrect === true && policyCheck)} 
                        className={!(nameCorrect === true && mailCorrect === true && messageCorrect === true && policyCheck) ?
                        "contact-form-button" : "contact-form-button-active" } 
                        type="submit"
                    >
                        {lang === "de" ? langDe.contacts.send : lang === "en" ? langEn.contacts.send : ""}
                    </button>
                    </form>
                </div>
            </div>
            <div className="contacts-arrow-up">
                <a href="#hero">
                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="37" height="37" rx="18.5" stroke="white" strokeWidth="2"/>
                        <path d="M18 15.828L13.343 20.485C12.9525 20.8755 12.3194 20.8755 11.929 20.485C11.5385 20.0945 11.5385 19.4615 11.929 19.071L18.2928 12.7071C18.6834 12.3166 19.3165 12.3166 19.7071 12.7071L26.071 19.071C26.4614 19.4615 26.4614 20.0945 26.071 20.485C25.6805 20.8755 25.0474 20.8755 24.657 20.485L20 15.828L20 27C20 27.5523 19.5522 28 19 28C18.4477 28 18 27.5523 18 27L18 15.828Z" fill="white"/>
                    </svg>
                </a>
            </div>
        </div>
    )
}

export default ContactsDesktop
