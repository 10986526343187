import "./ProjectModel.css"
function ProjectModelMobile({project, lang}) {
    return (
        <div id="project-model-mobile">
            <img className="project-model-img" src={project.img} alt={project.name} />
            <p className="project-model-headline project-model-headline-mobile">{project.name}</p>
            <p className="project-model-lang project-model-lang-mobile">{project.lang.map((lang, index) => {
                return <span key={index}>{lang}{index < project.lang.length - 1 ? ' |  ' : ''}</span>
            })
            }</p>
            <p className="project-model-desc project-model-desc-mobile">{lang === "de" ? project.descDE : lang === "en" ? project.descEN : ""}</p>
            <div className="project-model-buttons">
                <a href={project['test-url']} target="_blank" rel="noreferrer"><button className="project-model-button-live project-model-button-live-mobile">Live Test</button></a>
                <a href={project['git-url']} target="_blank" rel="noreferrer"><button className="project-model-button-github project-model-button-github-mobile">Github</button></a>
            </div>
        </div>
    )
}

export default ProjectModelMobile
