import "./AboutMe.css"
import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'
function AboutMeMobile({lang}) {
    return (
        <div id="aboutme" className="aboutme about-me-mobile">
            <h1 className="headlines-mobile">{lang === "de" ? langDe.aboutme.title : lang === "en" ? langEn.aboutme.title : ""}</h1>
            <p>{lang === "de" ? langDe.aboutme.desc : lang === "en" ? langEn.aboutme.desc : ""} </p>
            <div className="perso-point">
                <img src="bulb_desktop.png" alt="me" />
                <p>{lang === "de" ? langDe.aboutme.point1 : lang === "en" ? langEn.aboutme.point1 : ""}</p>
            </div>
            <div className="perso-point">
                <img src="puzzle_desktop.png" alt="me" />
                <p>{lang === "de" ? langDe.aboutme.point2 : lang === "en" ? langEn.aboutme.point2 : ""}</p>
            </div>
        </div>
    )
}

export default AboutMeMobile
