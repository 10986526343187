import './Skills.css'
import Button from "../models/Button/Button"
import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'

function SkillsDesktop({lang}) {
    return (
        <div id="skills" className='skills-desktop'>
            <div className="skills-img-con">
                <img src="HTML.png" alt="HTML" />
                <img src="CSS.png" alt="CSS" />
                <img src="Javascript.png" alt="Javascript" />
                <img src="API.png" alt="Api" />
                <img src="React.png" alt="React" />
                <img src="typescript.png" alt="TypeScript" />
                <img src="git.png" alt="Git" />
                <img src="scrum.png" alt="Scrum" />
            </div>
            <div className="skills-info">
                <h1 className='headlines-desktop'>{lang === "de" ? langDe.skills.title : lang === "en" ? langEn.skills.title : ""}</h1>
                <p className='skills-text'>{lang === "de" ? langDe.skills.desc : lang === "en" ? langEn.skills.desc : ""}</p>
                <a href='#contacts'><Button type="primary third">{lang === "de" ? langDe.hero.contact : lang === "en" ? langEn.hero.contact : ""}</Button></a>
            </div>
        </div>
    )
}

export default SkillsDesktop
