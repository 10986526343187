import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'
function HeaderDesktop({setImprint, setIsPrivate, lang}) {

    function handleLink() {
        setImprint(false);
        setIsPrivate(false);
    }

    return (
        <header>
            <h1 className='name'>Kay Schumacher</h1>
            <menu>
                <a href="#aboutme" onClick={handleLink}>{lang === 'de' ? langDe.menu.aboutme : lang === 'en' ? langEn.menu.aboutme : ""}</a>
                <a href="#skills" onClick={handleLink}>{lang === 'de' ? langDe.menu.skills : lang === 'en' ? langEn.menu.skills : ""}</a>
                <a href="#projects" onClick={handleLink}>{lang === 'de' ? langDe.menu.projects : lang === 'en' ? langEn.menu.projects : ""}</a>
                <a href="#contacts" onClick={handleLink}>{lang === 'de' ? langDe.menu.contact : lang === 'en' ? langEn.menu.contact : "" }</a>
            </menu>
        </header>
    )
}

export default HeaderDesktop
