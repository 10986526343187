import "./Success.css"
import AOS from "aos"
import "aos/dist/aos.css"
import { useEffect } from "react"
import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'
function Success({lang}) {

    useEffect(() => {
        AOS.init({duration: 2000});
    }, [])


    return (
        <div className="success" data-aos="fade-up" >
            <span>{lang === "de" ? langDe.success.text : lang === "en" ? langEn.success.text : ""}</span>
        </div>
    )
}

export default Success
