import ProjectModelMobile from "../models/ProjectModel/ProjectModelMobile"
import "./Projects.css"
import projects from "../../data/projects.json"
import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'

function ProjectsMobile({lang}) {
    return (
        <div id="projects" className="projects">
            <h1 className="headlines-mobile">{lang === "de" ? langDe.projects.title : lang === "en" ? langEn.projects.title : ""}</h1>
            <div className="projects-list-con">
                {projects.projects.map((project, index) => {
                    return <ProjectModelMobile project={project} key={index} lang={lang}/>
                })}    
            </div>
        </div>
    )
}

export default ProjectsMobile
