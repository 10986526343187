import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'

function AboutmeDesktop({lang}) {
    return (
        <div className="aboutme-desktop">
            <div className="aboutme">
                <h1 className="headlines-desktop">{lang === "de" ? langDe.aboutme.title : lang === "en" ? langEn.aboutme.title : ""}</h1>
                <p>{lang === "de" ? langDe.aboutme.desc : lang === "en" ? langEn.aboutme.desc : ""} </p>
                <div className="perso-point">
                    <img src="bulb_desktop.png" alt="me" />
                    <p>{lang === "de" ? langDe.aboutme.point1 : lang === "en" ? langEn.aboutme.point1 : ""}</p>
                </div>
                <div className="perso-point">
                    <img src="puzzle_desktop.png" alt="me" />
                    <p>{lang === "de" ? langDe.aboutme.point2 : lang === "en" ? langEn.aboutme.point2 : ""}</p>
                </div>
            </div>
            <div className="aboutme-pic">
                <img src="Kay_long.png" alt="me" />
            </div>
        </div>
    )
}

export default AboutmeDesktop
