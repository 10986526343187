import ProjectModelDesktop from "../models/ProjectModel/ProjectModelDesktop"
import projects from "../../data/projects.json"
import "./Projects.css"
import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'


function ProjectsDesktop({lang}) {

    return (
        <div id="projects" className="projects">
            <h1 className="headlines-desktop">{lang === "de" ? langDe.projects.title : lang === "en" ? langEn.projects.title : ""}</h1>
            <div className="projects-list projects-list-con">
                {projects.projects.map((project, index) => {
                    return <ProjectModelDesktop project={project} key={index} lang={lang} number={index} />
                })} 
            </div>
        </div>
    )
}

export default ProjectsDesktop
