import "./Impressum.css"
function ImpressumMobile() {
    return (
        <div id="impressum" className="impressum-mobile">
            <h1>Impressum</h1>
            <p><strong>Daten gemäß Abschnitt 5 TMG</strong></p>
            <p>Kay Schumacher</p>
            <p>Sterndamm 42<br />12487 Berlin</p>
            <p><strong>Kontaktdaten</strong></p>
            <p>Telefon: 0151 72400477</p>
            <p>E-Mail: Kay@uriori.de</p>
        </div>
    )
}

export default ImpressumMobile
