import './header.css'
function HeaderMobile({handleMobileMenu , isMobileMenu, lang}) {
    return (
        <header>
            <h1 className='name'>Kay Schumacher</h1>
            {isMobileMenu ? <div className='header-burger' onClick={handleMobileMenu}>&#10005;</div> :
                <div className='header-burger' onClick={handleMobileMenu}>&#9776;</div>
            }
            
        </header>
    )
}

export default HeaderMobile
