import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'

function FooterDesktop({setIsImprint, lang}) {
    return (
        <div className="footer-desktop">
            <div>
            <h1 className="name">Kay Schumacher</h1>
            <a href="#impressum"><h2 className="footer-imprint" onClick={() => setIsImprint(true)}>{lang === 'de' ? langDe.footer.imprint : lang === 'en' ? langEn.footer.imprint : ""}</h2></a>
            </div>
            <span className="copyright"> © Kay Schumacher 2024</span>
            <div className="div-social-desktop footer-social">
                <a href='https://github.com/urioriKay' target="_blank" rel="noreferrer"><img src='github.png' alt='github' href='https://github.com/urioriKay' /></a>
                <a href="#contacts"><img src='contact.png' alt='contact' /></a>
                <a href='https://www.linkedin.com/in/kay-schumacher-83610a321/' target="_blank" rel="noreferrer"><img src='linkedin.png' alt='linkedin' /></a>
            </div>
        </div>
    )
}

export default FooterDesktop
