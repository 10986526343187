import { useMediaQuery } from "react-responsive";
import HeaderMobile from "./components/Header/HeaderMobile";
import HeaderDesktop from "./components/Header/HeaderDesktop";
import HeroMobile from "./components/Hero/HeroMobile";
import HeroDesktop from "./components/Hero/HeroDesktop";
import Mobilemenu from "./components/Mobilemenu/Mobilemenu";
import { useState } from "react";
import AboutMeMobile from "./components/Aboutme/AboutMeMobile";
import SkillsMobile from "./components/Skills/SkillsMobile";
import SkillsDesktop from "./components/Skills/SkillsDesktop";
import AboutmeDesktop from "./components/Aboutme/AboutmeDesktop";
import ContactsDesktop from "./components/Contacts/ContactsDesktop";
import ContactsMobile from "./components/Contacts/ContactsMobile";
import ProjectsDesktop from "./components/Projects/ProjectsDesktop";
import ProjectsMobile from "./components/Projects/Projectsmobile";
import FooterDesktop from "./components/Footer/FooterDesktop";
import FooterMobile from "./components/Footer/FooterMobile";
import ImpressumMobile from "./components/Impressum/ImpressumMobile";
import PrivacyMobile from "./components/Privacy/PrivacyMobile";
import Success from "./components/Success/Success";



function App() {
  const [message, setMessage] = useState(new URLSearchParams(window.location.search).get('message'));
  const isBigScreen = useMediaQuery({ query: '(min-width: 800px)' });
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isImprint, setIsImprint] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [policyCheck, setPolicyCheck] = useState(false);
  const [messageCorrect, setMessageCorrect] = useState("Start");
  const [nameCorrect, setNameCorrect] = useState("Start");
  const [mailCorrect, setMailCorrect] = useState("Start");
  const [lang, setLang] = useState("de")


  function handleNameFocus(value) {
    value.length < 3 ? setNameCorrect("less") : setNameCorrect(true);
}

function handleMessageFocus(value) {
    value.length < 5 ? setMessageCorrect("less") : setMessageCorrect(true);
}



function handleMailFocus(value) {
    if (value.includes("@") === false) {
        setMailCorrect('at');
    }else if (value.indexOf("@") === 0) {
        setMailCorrect('before');
    } else if (!value.slice(value.indexOf("@")).includes(".")) {
        setMailCorrect('points');
    } else if (value.slice(value.indexOf("@")).indexOf(".") === 1) {
        setMailCorrect('after');
    } else if (value.slice(value.indexOf("@")).indexOf(".") > value.slice(value.indexOf("@")).length-3) {
        setMailCorrect('points-after');
    }else {
        setMailCorrect(true)
    }
}


  function handleMobileMenu () {
    setIsMobileMenu(!isMobileMenu);
  }

  if (message) {
   setTimeout(() => {
     setMessage(false);
   },2000)
  }
  

  return (
    <>
      {isBigScreen ? <HeaderDesktop setImprint={setIsImprint} setIsPrivate={setIsPrivate} lang={lang} /> :  <HeaderMobile handleMobileMenu={handleMobileMenu} isMobileMenu={isMobileMenu}  />}
      {!isBigScreen && isMobileMenu ? <Mobilemenu handleMobileMenu={handleMobileMenu} setIsImprint={setIsImprint} setIsPrivate={setIsPrivate} lang={lang} /> : ""}
      {isImprint && !isMobileMenu? <ImpressumMobile /> : ""}
      {isPrivate && !isMobileMenu? <PrivacyMobile /> : ""}
      {isImprint || isPrivate ? "" : isBigScreen  ? <HeroDesktop lang={lang} setLang={setLang} /> : isMobileMenu ? "" : <HeroMobile lang={lang} setLang={setLang} /> }
      {isImprint || isPrivate ? "" : isBigScreen ? <AboutmeDesktop lang={lang} /> : isMobileMenu ? "" : <AboutMeMobile lang={lang} />}
      {isImprint || isPrivate ? "" : isBigScreen ? <SkillsDesktop lang={lang} /> : isMobileMenu ? "" : <SkillsMobile lang={lang} /> }
      {isImprint || isPrivate ? "" : isBigScreen ? <ProjectsDesktop lang={lang} /> : isMobileMenu ? "" : <ProjectsMobile lang={lang} /> }
      {isImprint || isPrivate ? "" : isBigScreen ? 
      <ContactsDesktop policyCheck={policyCheck} setPolicyCheck={setPolicyCheck} 
                      handleNameFocus={handleNameFocus} 
                      handleMessageFocus={handleMessageFocus} 
                      handleMailFocus={handleMailFocus} nameCorrect={nameCorrect}
                      messageCorrect={messageCorrect} mailCorrect={mailCorrect}
                      setIsPrivate={setIsPrivate} lang={lang} /> :
      isMobileMenu ? "" : 
      <ContactsMobile policyCheck={policyCheck} setPolicyCheck={setPolicyCheck} 
                      handleNameFocus={handleNameFocus} handleMessageFocus={handleMessageFocus} 
                      handleMailFocus={handleMailFocus} nameCorrect={nameCorrect} 
                      messageCorrect={messageCorrect} mailCorrect={mailCorrect}
                      setIsPrivate={setIsPrivate} lang={lang} /> }
      {isBigScreen ? <FooterDesktop setIsImprint={setIsImprint} lang={lang} /> : isMobileMenu ? "" : <FooterMobile setIsImprint={setIsImprint} lang={lang} /> }
      {message ? <Success lang={lang} /> : ""}
    </>
  ) 
}

export default App;
