import Button from "../models/Button/Button"
import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'
import "./Skills.css"
function SkillsMobile({lang}) {
    return (
        <div id="skills" className="skills-mobile">
            <h1 className="headlines-mobile">{lang === "de" ? langDe.skills.title : lang === "en" ? langEn.skills.title : ""}</h1>
            <p>{lang === "de" ? langDe.skills.desc : lang === "en" ? langEn.skills.desc : ""}</p>
            <div className="skills-images">
                <img src="HTML.png" alt="HTML" />
                <img src="CSS.png" alt="CSS" />
                <img src="Javascript.png" alt="Javascript" />
                <img src="API.png" alt="Api" />
                <img src="React.png" alt="React" />
                <img src="typeScript.png" alt="TypeScript" />
                <img src="git.png" alt="Git" />
                <img src="scrum.png" alt="Scrum" />
            </div>
            <a href="#contacts"><Button type="primary">{lang === "de" ? langDe.hero.contact : lang === "en" ? langEn.hero.contact : ""}</Button></a>
        </div>
    )
}

export default SkillsMobile
