import './Hero.css'
import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'

import Button from "../models/Button/Button"
function HeroDesktop({lang, setLang}) {
    return (
        <section id='hero'>
            <div className="upper-desktop">
                <img className='hero-pic-desktop' src='Kay_long.png' alt='Kay with upperbody' />
                <div className='brand-div-con'>
                    <div className='brand-div'>
                        {/* <div className='brand-left brand-desktop'>{lang === 'de' ? langDe.hero.hi : lang === 'en' ? langEn.hero.hi: ""}</div> */}
                        <div className='brand-right brand-desktop'>
                            <span className='brand-right-name'>{lang === 'de' ? langDe.hero.name : lang === 'en' ? langEn.hero.name: ""}</span>
                            <span className='brand-right-job'>{lang === 'de' ? langDe.hero.role : lang === 'en' ? langEn.hero.role : ""}</span>
                        </div>
                    </div>
                    <a href="#contacts"><Button type='primary'>{lang === 'de' ? langDe.hero.contact : lang === 'en' ? langEn.hero.contact : ""}</Button></a>
                </div>
            </div>
            <div className='lower' id="aboutme">
                <div className='div-social-desktop'>
                    <a href='https://github.com/urioriKay' target="_blank" rel="noreferrer"><img src='github.png' alt='github' href='https://github.com/urioriKay' /></a>
                    <a href="#contacts"><img src='contact.png' alt='contact' /></a>
                    <a href='https://www.linkedin.com/in/kay-schumacher-83610a321/' target="_blank" rel="noreferrer"><img src='linkedin.png' alt='linkedin' /></a>
                    <img src='german.png' alt='german' onClick={() => setLang('de')} />
                    <img src='english.png' alt='english' onClick={() => setLang('en')} />
                </div>
                <span className='scroll-down'>{lang === 'de' ? langDe.hero.scroll : lang === 'en' ? langEn.hero.scroll : ""}--&gt;</span>
            </div>
        </section>
    )
}

export default HeroDesktop
