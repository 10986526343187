import './Mobilemenu.css'
import langDe from '../../data/lengDe.json'
import langEn from '../../data/lengEn.json'

function Mobilemenu({handleMobileMenu, setIsImprint, setIsPrivate, lang}) {

    function handleLink() {
        handleMobileMenu();
        setIsImprint(false);
        setIsPrivate(false);
    }

    return (
        <div className='mobilemenu'>
            <div className='menulists'>
                <a href='#aboutme' onClick={handleLink}>{lang === 'de' ? langDe.menu.aboutme : lang === 'en' ? langEn.menu.aboutme : ""}</a>
                <a href='#skills' onClick={handleLink}>{lang === 'de' ? langDe.menu.skills : lang === 'en' ? langEn.menu.skills : ""}</a>
                <a href='#projects' onClick={handleLink}>{lang === 'de' ? langDe.menu.projects : lang === 'en' ? langEn.menu.projects : ""}</a>
                <a href='#contacts' onClick={handleLink}>{lang === 'de' ? langDe.menu.contact : lang === 'en' ? langEn.menu.contact : "" }</a>
            </div>
        </div>
    )
}

export default Mobilemenu
